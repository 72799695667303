@import '~themes/vars.less';

body {
  height: 100%;
  overflow-y: hidden;
  background-color: #f8f8f8;
}

::-webkit-scrollbar-thumb {
  background-color: #e6e6e6;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.margin-right {
  margin-right: 16px;
}

:global {
  .ant-breadcrumb {
    &>span {
      &:last-child {
        color: #999;
        font-weight: normal;
      }
    }
  }

  .ant-breadcrumb-link {
    .anticon+span {
      margin-left: 4px;
    }
  }

  .ant-table {
    .ant-table-thead>tr>th {
      text-align: center;
    }

    .ant-table-tbody>tr>td {
      text-align: center;
    }

    &.ant-table-small {
      .ant-table-thead>tr>th {
        background: #f7f7f7;
      }

      .ant-table-body>table {
        padding: 0;
      }
    }
  }

  .ant-table-pagination {
    float: none !important;
    margin: 16px auto !important;
  }

  .ant-popover-inner {
    border: none;
    border-radius: 0;
    box-shadow: 0 0 20px rgba(100, 100, 100, 0.2);
  }

  .ant-form-item-control {
    vertical-align: middle;
  }

  .ant-modal-mask {
    background-color: rgba(55, 55, 55, 0.2);
  }

  .ant-modal-content {
    box-shadow: none;
  }

  .ant-select-dropdown-menu-item {
    padding: 12px 16px !important;
  }

  a:focus {
    text-decoration: none;
  }

  .ant-table-layout-fixed table {
    table-layout: auto;
  }
}

@media (min-width: 1600px) {
  :global {
    .ant-col-xl-48 {
      width: 20%;
    }

    .ant-col-xl-96 {
      width: 40%;
    }
  }
}

@media (max-width: 767px) {
  :global {

    .ant-pagination-item,
    .ant-pagination-next,
    .ant-pagination-options,
    .ant-pagination-prev {
      margin-bottom: 8px;
    }

    .ant-card {
      .ant-card-head {
        padding: 0 12px;
      }

      .ant-card-body {
        padding: 12px;
      }
    }
  }
}

/* Custom scrollbar */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #63cfe3;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
@cbas-color: #63cfe3;@cbas-hover-color: fade(@cbas-color, 10%);@primary-color: @cbas-color;@layout-header-color: @cbas-color;@layout-header-background: #fff;@back-top-bg: rgba(99, 207, 227, 0.45);@table-header-color: @cbas-color;@item-hover-bg: @cbas-hover-color;@table-row-hover-bg: @cbas-hover-color;