@import '~themes/vars.less';

.contentInner {
    background: #fff;
    padding: 24px;
    box-shadow: @shadow-1;
    min-height: ~'calc(100vh - 230px)';
    position: relative;
}

@media (max-width: 767px) {
    .contentInner {
        padding: 12px;
        min-height: ~'calc(100vh - 160px)';
    }
}
@cbas-color: #63cfe3;@cbas-hover-color: fade(@cbas-color, 10%);@primary-color: @cbas-color;@layout-header-color: @cbas-color;@layout-header-background: #fff;@back-top-bg: rgba(99, 207, 227, 0.45);@table-header-color: @cbas-color;@item-hover-bg: @cbas-hover-color;@table-row-hover-bg: @cbas-hover-color;