@import '~themes/vars.less';

.sider {
    box-shadow: fade(@primary-color, 10%) 0 0 28px 0;
    z-index: 10;

    :global {
        .ant-layout-sider-children {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
    }
}

.brand {
    z-index: 1;
    height: @header-height;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 24px;
    box-shadow: 0 1px 9px -3px rgba(0, 0, 0, 0.2);

    .logo {
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            width: 180px;
            margin-right: 8px;
        }

        img.small {
            width: 60px;
            margin-right: 8px;
        }

        h1 {
            vertical-align: text-bottom;
            font-size: 16px;
            text-transform: uppercase;
            display: inline-block;
            font-weight: 700;
            color: @primary-color;
            white-space: nowrap;
            margin-bottom: 0;
            .text-gradient();

            :local {
                animation: fadeRightIn 300ms @ease-in-out;
                animation-fill-mode: both;
            }
        }
    }
}

.menuContainer {
    height: calc(~"100vh - (@{header-height} + 48px)");
    overflow-x: hidden;
    // flex: 1;
    padding: 12px 0;
    overflow-y: auto;

    :global {
        .ant-menu-inline {
            border-right: none;
        }
    }
}

.switchTheme {
    width: 100%;
    height: 48px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px;
    overflow: hidden;
    transition: all 0.3s;

    span {
        white-space: nowrap;
        overflow: hidden;
        font-size: 12px;
    }

    :global {
        .anticon {
            min-width: 14px;
            margin-right: 4px;
            font-size: 14px;
        }
    }
}

@keyframes fadeLeftIn {
    0% {
        transform: translateX(5px);
        opacity: 0;
    }

    100% {
        transform: translateX(0);
        opacity: 1;
    }
}
@cbas-color: #63cfe3;@cbas-hover-color: fade(@cbas-color, 10%);@primary-color: @cbas-color;@layout-header-color: @cbas-color;@layout-header-background: #fff;@back-top-bg: rgba(99, 207, 227, 0.45);@table-header-color: @cbas-color;@item-hover-bg: @cbas-hover-color;@table-row-hover-bg: @cbas-hover-color;