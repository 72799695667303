@import './default.less';
@import './mixin.less';

@header-height: 68px;
@sider-width: 240px;
@content-padding: 20px;

:export {
    headerHeight: @header-height;
    siderWidth: @sider-width;
}
@cbas-color: #63cfe3;@cbas-hover-color: fade(@cbas-color, 10%);@primary-color: @cbas-color;@layout-header-color: @cbas-color;@layout-header-background: #fff;@back-top-bg: rgba(99, 207, 227, 0.45);@table-header-color: @cbas-color;@item-hover-bg: @cbas-hover-color;@table-row-hover-bg: @cbas-hover-color;