@import '~themes/vars.less';

.globalFooter {
  text-align: center;

  .links {
    margin-bottom: 8px;

    a {
      color: @text-color-secondary;
      transition: all 0.3s;

      &:not(:last-child) {
        margin-right: 40px;
      }

      &:hover {
        color: @text-color;
      }
    }
  }

  .copyright {
    color: @text-color-secondary;
    font-size: @font-size-base;
  }
}
@cbas-color: #63cfe3;@cbas-hover-color: fade(@cbas-color, 10%);@primary-color: @cbas-color;@layout-header-color: @cbas-color;@layout-header-background: #fff;@back-top-bg: rgba(99, 207, 227, 0.45);@table-header-color: @cbas-color;@item-hover-bg: @cbas-hover-color;@table-row-hover-bg: @cbas-hover-color;