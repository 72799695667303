@import '~themes/vars.less';

.header {
    padding: 0;
    box-shadow: @shadow-2;
    position: relative;
    display: flex;
    justify-content: space-between;
    height: @header-height;
    z-index: 9;
    align-items: center;
    background-color: #fff;

    &.fixed {
        position: fixed;
        top: 0;
        right: 0;
        width: calc(~"100% - @{sider-width}");
        z-index: 29;
        transition: width 0.2s;

        &.collapsed {
            width: ~'calc(100% - 80px)';
        }
    }

    :global {
        .ant-menu-submenu-title {
            height: @header-height;
        }

        .ant-menu-horizontal {
            line-height: @header-height;

            &>.ant-menu-submenu:hover {
                color: @primary-color;
                background-color: @hover-color;
            }
        }

        .ant-menu {
            border-bottom: none;
            height: @header-height;
        }

        .ant-menu-horizontal>.ant-menu-submenu {
            top: 0;
            margin-top: 0;
        }

        .ant-menu-horizontal>.ant-menu-item,
        .ant-menu-horizontal>.ant-menu-submenu {
            border-bottom: none;
        }

        .ant-menu-horizontal>.ant-menu-item-active,
        .ant-menu-horizontal>.ant-menu-item-open,
        .ant-menu-horizontal>.ant-menu-item-selected,
        .ant-menu-horizontal>.ant-menu-item:hover,
        .ant-menu-horizontal>.ant-menu-submenu-active,
        .ant-menu-horizontal>.ant-menu-submenu-open,
        .ant-menu-horizontal>.ant-menu-submenu-selected,
        .ant-menu-horizontal>.ant-menu-submenu:hover {
            border-bottom: none;
        }
    }

    .rightContainer {
        display: flex;
        align-items: center;

        .iconButton {
            width: 48px;
            height: 48px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 24px;
            cursor: pointer;
            .background-hover();

            &:hover {
                .iconFont {
                    color: @primary-color;
                }
            }

            &+.iconButton {
                margin-left: 8px;
            }

            .iconFont {
                color: #b2b0c7;
                font-size: 24px;
            }
        }
    }

    .button {
        width: @header-height;
        height: @header-height;
        line-height: @header-height;
        text-align: center;
        font-size: 18px;
        cursor: pointer;
        transition: @transition-ease-in;

        &:hover {
            color: @primary-color;
            background-color: @hover-color;
        }
    }

    :global {
        .ant-avatar>img {
            object-fit: contain;
        }
    }
}

.notification {
    padding: 24px 0;
    width: 320px;

    .notificationItem {
        transition: all 0.3s;
        padding: 12px 24px;
        cursor: pointer;

        &:hover {
            background-color: @hover-color;
        }
    }

    .clearButton {
        text-align: center;
        height: 48px;
        line-height: 48px;
        cursor: pointer;
        .background-hover();
    }
}

.notificationPopover {
    :global {
        .ant-popover-inner-content {
            padding: 0;
        }

        .ant-popover-arrow {
            display: none;
        }

        .ant-list-item-content {
            flex: 0;
            margin-left: 16px;
        }
    }
}

@media (max-width: 767px) {
    .header {
        width: 100% !important;
    }
}
@cbas-color: #63cfe3;@cbas-hover-color: fade(@cbas-color, 10%);@primary-color: @cbas-color;@layout-header-color: @cbas-color;@layout-header-background: #fff;@back-top-bg: rgba(99, 207, 227, 0.45);@table-header-color: @cbas-color;@item-hover-bg: @cbas-hover-color;@table-row-hover-bg: @cbas-hover-color;