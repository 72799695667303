.loaderSvg {
    width: 100%;
    height: 100%;
    position: fixed;
    // background: rgba(0, 0, 0, 0.5);
    z-index: 9999;
    left: 0;
    top: 0;

    img {
        top: 45%;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: +999;
        position: absolute;
        text-align: center;
        margin: 0 auto;
        height: 10%;
        left: 0;
    }
}
@cbas-color: #63cfe3;@cbas-hover-color: fade(@cbas-color, 10%);@primary-color: @cbas-color;@layout-header-color: @cbas-color;@layout-header-background: #fff;@back-top-bg: rgba(99, 207, 227, 0.45);@table-header-color: @cbas-color;@item-hover-bg: @cbas-hover-color;@table-row-hover-bg: @cbas-hover-color;