.ellipsis {
  display: inline-block;
  width: 100%;
  overflow: hidden;
  word-break: break-all;
}

.lines {
  position: relative;
  .shadow {
    position: absolute;
    z-index: -999;
    display: block;
    color: transparent;
    opacity: 0;
  }
}

.lineClamp {
  position: relative;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
}

@cbas-color: #63cfe3;@cbas-hover-color: fade(@cbas-color, 10%);@primary-color: @cbas-color;@layout-header-color: @cbas-color;@layout-header-background: #fff;@back-top-bg: rgba(99, 207, 227, 0.45);@table-header-color: @cbas-color;@item-hover-bg: @cbas-hover-color;@table-row-hover-bg: @cbas-hover-color;