@import '~themes/vars.less';

.numberCard {
  padding: 32px 12px;
  margin-bottom: 24px;
  cursor: pointer;

  .iconWarp {
    font-size: 30px;
    padding-right: 10px;
  }

  .content {
    width: 100%;
    text-align: center;

    .title {
      // line-height: 16px;
      font-size: 18px;
      margin-bottom: 8px;
      // height: 16px;
      .text-overflow();
      white-space: normal;
    }

    .number {
      line-height: 32px;
      font-size: 24px;
      height: 32px;
      .text-overflow();
      margin-bottom: 0;
    }
  }
}
@cbas-color: #63cfe3;@cbas-hover-color: fade(@cbas-color, 10%);@primary-color: @cbas-color;@layout-header-color: @cbas-color;@layout-header-background: #fff;@back-top-bg: rgba(99, 207, 227, 0.45);@table-header-color: @cbas-color;@item-hover-bg: @cbas-hover-color;@table-row-hover-bg: @cbas-hover-color;